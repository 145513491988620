<template>
  <div class="m-teams-list">
    <b-notification
      v-if="deleteError"
      type="is-danger"
      has-icon
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('team.teamDeleteError') }}
    </b-notification>
    <b-table :data="teams" :paginated="true" :per-page="20" :loading="loading" default-sort="name">
      <b-table-column field="id" label="ID" sortable v-slot="props">
        <router-link :to="{ name: 'team-edit', params: { id: props.row.id } }">{{ props.row.id }}</router-link>
      </b-table-column>

      <b-table-column field="name" :label="$t('team.name')" sortable searchable v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column field="ownerUser" :label="$t('team.owner')" sortable searchable v-slot="props">
        {{ props.row.ownerUser }}
      </b-table-column>

      <b-table-column field="leaderUser" :label="$t('team.leader')" sortable searchable v-slot="props">
        {{ props.row.leaderUser }}
      </b-table-column>

      <b-table-column field="membersCount" :label="$t('team.membersCount')" v-slot="props">
        {{ props.row.membersCount }}
      </b-table-column>

      <b-table-column :label="$t('team.managed')" v-slot="props">
        <span>
          <b-icon v-if="props.row.managed" pack="fas" icon="check" />
        </span>
      </b-table-column>

      <b-table-column field="date" :label="$t('generic.modifiedDate')" sortable centered v-slot="props">
        <span class="tag">
          {{ props.row.modified }}
        </span>
      </b-table-column>

      <b-table-column field="options" :label="$t('generic.options')" centered v-slot="props">
        <b-button type="is-danger" icon-right="trash" @click="confirmDelete(props.row.id, props.row.type)" />
      </b-table-column>
      <template slot="empty">
        <no-data-to-list />
      </template>
    </b-table>
  </div>
</template>
<script>
import * as actions from '../../../store/actions';
import * as namespaces from '../../../store/namespaces';
import logger from '../../../utils/logger';
import NoDataToList from '../_fragments/NoDataToList';

export default {
  name: 'TeamsList',
  components: { NoDataToList },
  data() {
    return {
      isMenuActive: true,
      loading: false,
      loadingError: false,
      deleteError: false
    };
  },
  computed: {
    teams() {
      let data = [];
      if (this.$store.state.teams.allMap) {
        for (let [key, item] of Object.entries(this.$store.state.teams.allMap)) {
          data.push({
            id: key,
            name: item.name,
            ownerUser: item.ownerUser ? item.ownerUser.nameLast + ' ' + item.ownerUser.nameFirst : '',
            leaderUser: item.leaderUser ? item.leaderUser.nameLast + ' ' + item.leaderUser.nameFirst : '',
            membersCount: item.members ? item.members.length : 0,
            managed: item.managed,
            modified: new Date(item.updatedAt).toLocaleDateString()
          });
        }
      }
      return data;
    }
  },
  mounted() {
    this.loading = true;
    this.loadingError = false;
    this.reloadTeams();
  },
  methods: {
    reloadTeams() {
      this.loading = true;
      this.$store.dispatch(namespaces.TEAMS + actions.FETCH_TEAMS).then(() => {
        this.loading = false;
      });
    },
    confirmDelete(teamId) {
      this.$buefy.dialog.confirm({
        message: this.$t('team.confirmDelete'),
        cancelText: this.$t('generic.cancelButton'),
        confirmText: this.$t('generic.yesButton'),
        focusOn: 'cancel',
        onConfirm: () => {
          this.$store
            .dispatch(namespaces.TEAMS + actions.DELETE_TEAM, teamId)
            .then(() => {
              this.deleteError = false;
            })
            .catch(error => {
              this.deleteError = true;
              logger.error(error);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
